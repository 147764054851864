import React from "react";
import { BlockUI } from "ns-react-block-ui";
import { useState, useEffect } from "react";
import Select from "react-select";
import "../styles/ProductFilters.scss";
import { createRoot } from "react-dom/client";

export default function ProductFilter() {
    const [blocking, setBlocking] = useState(false);

    const url = "https://kzrepuestos.kintierp.com/v1/store";
    const headers = {
        "Content-type": "application/json",
        "x-api-key": "zKEEmDQg.cAimIlDT1nVkVSex87q6kVwegcYKHmwt",
    };

    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);

    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);

    useEffect(() => {
        setBlocking(true);
        try {
            fetch(`${url}/filters/get_params/`, {
                headers,
            })
                .then((response) => response.json())
                .then((json) => {
                    setBrands(json);
                    setBlocking(false);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    }, []);

    const handleChangeBrand = async (e) => {

        setSelectedModel(null)
        setModels([])

        setSelectedCategory(null)
        setCategories([])

        setSelectedSubcategory(null)
        setSubcategories([])

        setSelectedYear(null)
        setYears([])

        setSelectedBrand(e);
        setBlocking(true);
        try {
            await fetch(
                `${url}/filters/get_subparams/?` +
                new URLSearchParams({
                    parameter: e.value,
                }),
                {
                    headers,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    setModels(json);
                    setBlocking(false);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    };

    const handleChangeModel = async (e) => {

        setSelectedCategory(null)
        setCategories([])

        setSelectedSubcategory(null)
        setSubcategories([])

        setSelectedYear(null)
        setYears([])


        setSelectedModel(e);
        setBlocking(true);
        try {
            await fetch(
                `${url}/filters/get_categories/?` +
                new URLSearchParams({
                    parameter: selectedBrand.value,
                    subparameter: e.value,
                }),
                {
                    headers,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    setCategories(json);
                    setBlocking(false);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    };

    const handleChangeCategory = async (e) => {

        setSelectedSubcategory(null)
        setSubcategories([])

        setSelectedYear(null)
        setYears([])


        setSelectedCategory(e);
        setBlocking(true);
        try {
            await fetch(
                `${url}/filters/get_subcategories/?` +
                new URLSearchParams({
                    parameter: selectedBrand.value,
                    subparameter: selectedModel.value,
                    category: e.value,
                }),
                {
                    headers,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    setSubcategories(json);
                    setBlocking(false);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    };

    const handleChangeSubcategory = async (e) => {
        setSelectedYear(null)
        setYears([])

        setSelectedSubcategory(e);
        setBlocking(true);
        try {
            await fetch(
                `${url}/filters/get_years/?` +
                new URLSearchParams({
                    parameter: selectedBrand.value,
                    subparameter: selectedModel.value,
                    category: selectedCategory.value,
                    subcategory: e.value,
                }),
                {
                    headers,
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    setBlocking(false);
                    setYears(json);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let baseUrl = "productos?";

        if (selectedBrand) {
            baseUrl += `marca=${selectedBrand.value}&`;
        }
        if (selectedModel) {
            baseUrl += `modelo=${selectedModel.value}&`;
        }
        if (selectedCategory) {
            baseUrl += `categoria=${selectedCategory.value}&`;
        }
        if (selectedSubcategory) {
            baseUrl += `subcategoria=${selectedSubcategory.value}&`;
        }
        if (selectedYear) {
            baseUrl += `valor=${parseInt(selectedYear.value)}`;
        }

        window.location.href = baseUrl;
    };

    return (
        <BlockUI
            blocking={blocking}
            loader="default"
            mode="stretch"
            cursor="wait"
            overlayStyle={{
                background: "#F3F4F6",
                opacity: 0.5,
            }}
        >
            <div className="filters">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center font-18 bold">
                            Ingresa los datos del vehículo
                        </div>
                    </div>
                </div>
                <hr />

                <form className="row" onSubmit={handleSubmit}>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Marca:</label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={selectedBrand}
                                onChange={(e) => handleChangeBrand(e)}
                                options={brands}
                                placeholder="Selecciona una marca"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Modelo:</label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={selectedModel}
                                onChange={(e) => handleChangeModel(e)}
                                options={models}
                                placeholder="Selecciona un modelo"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Categoría:</label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={selectedCategory}
                                onChange={(e) => handleChangeCategory(e)}
                                options={categories}
                                placeholder="Selecciona una categorías"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Subcategoría:</label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={selectedSubcategory}
                                onChange={(e) => handleChangeSubcategory(e)}
                                options={subcategories}
                                placeholder="Selecciona una subcategoría"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Año:</label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e)}
                                options={years}
                                placeholder="Selecciona un año"
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="d-grid gap-1">
                            <button type="submit" className="btn btn-primary">
                                BUSCAR REPUESTO
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </BlockUI>
    );
}

if (document.getElementById("product-filter")) {
    const component = createRoot(document.getElementById("product-filter"));
    component.render(<ProductFilter />);
}
