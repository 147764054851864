import React from "react";
import "../styles/CategoryFilters.scss";
import { createRoot } from "react-dom/client";
import { useFetch } from "../utils/useFetch";

export default function CategoryFilter() {
    const { data, loading, error, handleCancelRequest } = useFetch(
        "categories/get_categories/"
    );

    if (data) {
        return (
            <div className="mb-3">
                <ul className="list-group">
                    <li
                        className="list-group-item list-group-item-action active"
                        aria-current="true"
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <div className="title">Categorías</div>
                        </div>
                    </li>
                    {data.map((item) => (
                        <li className="list-group-item pointer" key={item.id}>
                            <a href={`/productos?categoria=${item.id}`}>
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

if (document.getElementById("category-filter")) {
    const component = createRoot(document.getElementById("category-filter"));
    component.render(<CategoryFilter />);
}
