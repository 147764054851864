// resoureces/js/app.js

require('./bootstrap');

// React Components
require('./components/MainCategoryList')
require('./components/ProductFilters')
require('./components/CategoryFilters')
require('./components/Search')



