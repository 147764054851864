import React from "react";
import "../styles/MainCategoryList.scss";
import { createRoot } from "react-dom/client";
import CategoryCard from "./CategoryCard";
import { useFetch } from "../utils/useFetch.js";

export default function CategoryList() {
    const { data, loading, error, handleCancelRequest } = useFetch(
        "categories/get_limit_categories/?limit=6"
    );

    if (data) {
        return (
            <React.Fragment>
                <p className="text-danger">{error}</p>
                <div className="row">
                    {data.map((item) => (
                        <div className="col-6 col-md-2 mb-3" key={item.id}>
                           <CategoryCard category={item} />
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

if (document.getElementById("category-list")) {
    const component = createRoot(document.getElementById("category-list"));
    component.render(<CategoryList />);
}
