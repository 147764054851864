export default function CategoryCard({ category }) {
    return (
        <a
            href={`/productos?categoria=${category.id}`}
            className="card category-card"
        >
            <div className="card-body text-center">
                <img
                    src={category.image_url}
                    alt={category.name}
                    title={category.name}
                    loading="lazy"
                />
                <p className="card-text">{category.name}</p>
            </div>
        </a>
    );
}
