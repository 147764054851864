import React from "react";
import { createRoot } from "react-dom/client";
import {useState} from 'react';

export default function Search() {

    const [search, setSearch] = useState('');

    const handleChange = event => {
        setSearch(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let baseUrl = "productos";
        if (search) {
            baseUrl += `?search=${search}`;
            window.location.href = baseUrl;
        } else {
            window.location.href = baseUrl;
        }
    };

    return (
        <form className="d-flex" onSubmit={handleSubmit}>
            <input className="form-control field-search" type="text"
                placeholder="Buscar por nombre, marca, modelo, categoría ..." onChange={handleChange}
                value={search} />
            <button type="submit" className="btn btn-primary button-search" >
                <span className="mdi mdi-magnify"></span>
                <span style={{ fontSize: '0px' }}>Buscar</span>
            </button>
        </form >
    );
}

if (document.getElementById("search")) {
    const component = createRoot(document.getElementById("search"));
    component.render(<Search />);
}
